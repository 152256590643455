body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: 1.5;
}

h1,
h2 {
  color: $black;
  font-family: $heading-font-family;
  font-size: em(30,12);
  margin: 0 0 .25em;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
}

h3 {
  font-family: $heading-font-family;
  font-size: em(21,12);
  margin: 0 0 .25em;
  color: #fff;
  letter-spacing: 1px;
  -moz-osx-font-smoothing: grayscale;
}

h4 {
  font-family: $base-font-family;
  font-size: em(18,12);
  font-weight: bold;
  margin: 0 0 .25em;
}

h5 {
  font-family: $base-font-family;
  font-size: em(12,12);
  font-weight: bold;
  margin: 0 0 .25em;
  color: $black;
}

p {
  margin: 0 0 .75em;
  strong {
    font-weight: bold;
    color: $black;
    margin-bottom: 10px;
  }
}

a {
  color: $red;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: #afafaf;
  }
}

hr {
  color: #fafafa;
	margin: 0px 0px 13px 0px;
}
