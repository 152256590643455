.header {
	background-color: #000000;
	border-bottom: 2px solid #191919;
	height: 95px;
	width: 100%;
	position: relative;

	.header-wrap {
		height: 100%;
		margin: 0 auto;
    padding: 0 1em;

		.slogan {
			@include span-columns(5.25 of 7.25);
			display: none;
			font-size: 12px;
			color: #7b7b7b;
			font-style: italic;
			margin-top: 1.25em;

			@include media($medium-screen-up) {
				display: block;
				text-align: right;
			}
			@include media($small-desktop-up) {
				font-size: 15px;
				margin-top: .5em;
				text-align: center;
			}

			span {
				color: #b9b9b9;
			}
		}
		.header-right {
			@include span-columns(6.75);
			display: none;
			@include media($medium-screen-up) {
				display: block;
			}
			@include media($small-desktop-up) {
				@include span-columns(7.25);
			}
			.secondary-menu {
				@include span-columns(7.25 of 7.25);
			}

			ul {
				float: right;
				margin: .5em 0 1em;

				li {
					display: inline-block;
					border-right: 1px solid #8e8d8d;
					padding: 0 5px 0 3px;
					line-height: 1;
					&:last-child {
						padding-right: 0;
						border: none;
					}
					a {
						color: #8e8d8d;
						font-size: 11px;
						font-weight: bold;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			a.buy-now-btn {
				@include span-columns(2 of 7.25);
				@extend .btn-primary;
				display: block;
				float: right;
				position: relative;
				margin-right: 0;
				padding-left: 0;
				padding-right: 0;
			}
		}
		a.logo {
			@include span-columns(10);
			margin-top: 1.25em;
			@include media($medium-screen-up) {
				@include span-columns(5.25)
				//margin-top: 0;
			}
			@include media($small-desktop-up) {
				@include span-columns(4.75)
				margin-top: 0;
			}
			img {
				width: 300px;
				@include media($small-desktop-up) {
					width: 100%;
				}
			}
		}
	}
}

.access {
	margin: 0 auto;
	position: relative;
	padding: 0;
	z-index: 1;
	max-width: 1020px;
}

.menu-primary-container {
	@include span-columns(12);
	position: absolute;
	top: 0;
	box-shadow: 0px -4px 10px #000;
	display: none;
	@include media($medium-screen-up) {
		display: block;
	}
	ul li {
		@include span-columns(2.621);
		margin: 0;
		border: 1px solid #000;
		box-shadow: inset 0px 0px 3px #414141;
		display: inline-block;
		background: #282828;
		a {
			color: $light-gray;
			background: -webkit-linear-gradient(#fff, #afafaf);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-family: $heading-font-family;
			display: block;
			font-size: em(16,12);
			text-decoration: none;
			letter-spacing: 1px;
			padding: .5em 0;
			text-align: center;
			@include media($small-desktop-up) {
				font-size: em(21,12);
			}
		}
	}
	.current-menu-item,
	li:hover {
		background: #cfcfcf; /* Old browsers */
		background: -moz-linear-gradient(top,  #ffffff 0%, #8b8b8b 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #ffffff 0%,#8b8b8b 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #ffffff 0%,#8b8b8b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#8b8b8b',GradientType=0 ); /* IE6-9 */
		box-shadow: inset 0px 2px 5px rgba(#000, .5);
		a {
			color: #000;
			background: -webkit-linear-gradient(#000, #000);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-family: $heading-font-family;
			text-shadow: 1px 1px 1px rgba(#fff, .25);
		}
	}
}
