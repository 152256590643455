.page-middle {
	background: #343434; /* Old browsers */
	background: -moz-linear-gradient(top,  #343434 0%, #000000 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #343434 0%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #343434 0%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#343434', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	border-top: 2px solid #000;
	border-bottom: 1px solid #fff;
	box-shadow: 1px 1px 8px rgba(#000, .5);
	top: -1px;
	position: relative;
  display: none;
	@include media($medium-screen-up) {
	 height: 40px;
	 display: block;
	}
	@include media($small-desktop-up) {
		height: 56px;
	}
}

a.faq-link {
	color: $black;
}

.ui-accordion-header {
	margin: 1.25em 0 .5em;
	display: block;
}

.who-we-are-content {
	img {
		margin-bottom: 1em;
	}
}

.ngg-gallery-thumbnail {
	margin: 0 5px 5px 0!important;
}

iframe.EasyLocator {
	display: none;
	@include media($small-desktop-up) {
		display: block;
	}
}

iframe.EasyLocator-mobile {
	display: block;
	#map_container {
		height: 50%;
	}
	@include media($small-desktop-up) {
		display: none;
	}
}

.content-area {
  .step {
    @include span-columns(12 of 12);
    margin-bottom: 1.5em;
  }
  .step-image {
    @include span-columns(5 of 12);
    @include media($mobile-screen-down) {
        @include span-columns(12 of 12);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .step-instructions {
    @include span-columns(7 of 12);
    @include media($mobile-screen-down) {
        @include span-columns(12 of 12);
    }
  }
}
