footer {
	background-color: #f4f4f4;
	background-image: url(./images/footer-bg.jpg);
	background-position: left top;
	background-repeat: repeat-x;
	width: 100%;
	clear:both;
}

.footer-wrap {
	background: url(./images/map.png) no-repeat scroll right 180px transparent;
	padding: 2.25em 1em;
  h2 {
    color: #262626;
    font-weight: bold;
    margin-bottom: 5px;
    font-size:18px;

    a {
      color: #d41800;
      text-decoration: none;

      &:hover {
        color: #afafaf;
      }
    }
  }
}

.brands-intro {
	@include media($mobile-screen-down) {
		text-align: center;
	}
}


.footer-left {
	@include span-columns(12 of 12);
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 2em;
	@include media($medium-screen-up) {
		@include span-columns(6.25 of 12);
		border-right: 1px solid #d7d7d7;
		border-bottom: none;
		padding-right: 1.5em;
	}

	.left-column {
		@include span-columns(12 of 12);
		@include media($mobile-screen-down) {
			text-align: center;
		}
		@include media($medium-screen-up) {
				@include span-columns(6 of 6)
		}
		@include media($small-desktop-up) {
				@include span-columns(1.25 of 6);
		}
		p {
			color: #424242;
			font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	.middle-column {
		@include span-columns(6 of 12);
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
			text-align: center;
			margin: 0 0 1.5em;
		}
		@include media($medium-screen-up) {
			@include span-columns(3 of 6);
		}
		@include media($small-desktop-up) {
			@include span-columns(2.5 of 6);
		}

		p {
			a {
				color: #d41800;
				text-decoration: none;
			}
			span {
				display: block;
				a {
					color: #727272;
				}
			}
		}
	}
	.middle-column,
	.right-column {
		p {
			color: #727272;
			font-size: 10px;
			line-height: 15px;
			a {
				&:hover {
					color: #afafaf;
					text-decoration: none;
				}
			}
		}
		span {
			color: #727272;
			font-size: 11px;
		}
	}
	.right-column {
		@include span-columns(6 of 12);
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
			text-align: center;
		}
		@include media($medium-screen-up) {
			@include span-columns(3 of 6);
		}
		@include media($small-desktop-up) {
			@include span-columns(2.25 of 6);
		}
		margin-right: 0;
		p {
			margin-bottom: 20px;
		}
	}
}

.footer-right {
	@include span-columns(12 of 12);
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 2em;
	@include media($medium-screen-up) {
		@include span-columns(5.75 of 12);
		margin-right: 0;
		border: none;
	}
	position: relative;

	.left-column {
    @include span-columns(3 of 6);
		text-align: center;
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
			display: block;
			margin: 0 auto 1em;
		}
		@include media($medium-screen-up) {
			@include span-columns(3 of 6);
			img {
				max-width: 100%;
			}
		}
		img {
			max-width: 200px;
		}
    hr {
    	margin:10px 0;
    }
	}

	.right-column {
		@include span-columns(3 of 6);
		margin: 0;
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
			display: block;
			margin: 0 auto 2em;
			position: relative;
		}
		@include media($medium-screen-up) {
			@include span-columns(3 of 6);
			img {
				width: 100%;
			}
		}


		p {
			color: #727275;
			font-size: 11px;
			line-height: 15px;
			strong {
		 		color: #424242;
		 		font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
		 		font-size: 13px;
		 		font-weight: bold;
		  }
			a {
				color: #d41800;
				text-decoration: none;
			}
		}

		p.narrow-p {
		 a:hover {
			color: #afafaf;
		 }
	  }
	}
}

.social-media {
	height: 40px;
	margin: 20px 0 0;
	display: block;
	width: 168px;
	@include media($mobile-screen-down) {
		margin: 10px auto 0;
	}

	a.facebook {
    	background-image: url(./images/facebook-sb.png);
    	background-position: 0 -1px;
    	background-repeat: no-repeat;
    	display: block;
    	float: left;
    	margin: 0px 4px 0px 0px;
    	height:36px;
    	width:39px;
		&:hover {
      background-image: url(./images/facebook-sb.png);
     	background-position: 0 -37px;
     	background-repeat: no-repeat;
		}
	}

	a.twitter {
    	background-image: url(./images/twitter-sb.png);
    	background-position: 0 -1px;
    	background-repeat: no-repeat;
    	display: block;
    	float: left;
    	margin: 0px 4px 0px 0px;
    	height:36px;
    	width:39px;
		&:hover {
      	background-image: url(./images/twitter-sb.png);
      	background-position: 0 -36px;
      	background-repeat: no-repeat;
		}
	}
	a.youtube{
    	background-image: url(./images/youtube-sb.png);
    	background-position: 0 0;
    	background-repeat: no-repeat;
    	display: block;
    	float: left;
    	height:37px;
    	width:39px;
    	margin-right:4px;
		&:hover {
      	background-image: url(./images/youtube-sb.png);
      	background-position: 0 -36px;
      	background-repeat: no-repeat;
		}
	}
  a.pinterest{
    	background: url(./images/pinterest-sb.png) 0 0 no-repeat;
    	display:block;
    	float:left;
    	height:37px;
    	width:39px;
    &:hover {
    	background-position: 0px -37px;
    }
  }
}


/* LINKS */
.thumbs {
	@include align-items(center);
	@include display(flex);
	@include flex-direction(row);
	@include flex-wrap(wrap);
	@include justify-content(center);
  margin-bottom: 30px;
	margin-top: 20px;
	z-index: 100;
	position: relative;
}

.thumb {
	@include flex(1);
	margin: 0 5px 10px;
	height: 71px;
	position: relative;
	background: -moz-linear-gradient(left, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.1) 25%, rgba(255,255,255,0.05) 50%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.15) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.15) 0%,rgba(0,0,0,0.1) 25%,rgba(255,255,255,0.05) 50%,rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.15) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(0,0,0,0.15) 0%,rgba(0,0,0,0.1) 15%,rgba(255,255,255,0.05) 50%,rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.15) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 */
	border-radius: 5px;
	&:hover {
		background: none;
		img {
			opacity: 1;
			filter: contrast(100%);
		}
	}
	img {
		@include transition(opacity 300ms ease-in-out);
		z-index: -1;
		position: relative;
		filter: contrast(75%);
		opacity: .5;
    &:hover {
      cursor: pointer;
    }
	}
}

.footer-bottom {
	@include span-columns(12 of 12);

	span {
		@include span-columns(12 of 12);
		margin-bottom: 1em;
		@include media($mobile-screen-down) {
			text-align: center;
		}
		@include media($small-desktop-up) {
			@include span-columns(1.5 of 12);
			margin: 0;
		}
			color: #424242;
			font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
	}
	form {
		@include span-columns(6 of 12);
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
		}
		@include media($small-desktop-up) {
			@include span-columns(4.75 of 12);
		}
	}
	.copyright {
		@include span-columns(6 of 12);
		@include media($mobile-screen-down) {
			@include span-columns(12 of 12);
			text-align: center;
		}
		@include media($small-desktop-up) {
			@include span-columns(5.75 of 12);
		}
		text-align: right;
		margin-right: 0;
	}
}

footer {
  .myemma {
    float: left;
    display: block;
  }

  .myemma__title {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .myemma__form-group {
    clear: both;
    height: 50px;
  }

  .myemma__label {
    color: transparent;
    height: 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
  }

  .myemma__email {
    box-sizing: border-box;
    padding: 10px;
    width: 64%;
    height: 37px;
    display: inline-block;
    float: left;
    margin-right: -1px;
  }

  .myemma__button {
    border: 1px solid #d41800;
    color: #d41800;
    background: none;
    box-sizing: border-box;
    display: inline-block;
    float: left;
    height: 37px;
    width: 35%;
    padding: 15px 15px 12px;
    margin: 0;
    font-size: 16px;
    cursor: pointer;
    transition: background-color .25s ease, color .25s ease;
  }

	input[type="submit"] {
		background: none;
	}

  .myemma__button:hover {
    background-color: #d41800;
    color: white;
  }

  .myemma__error {
    color: #d41800;
    display: block;
  }
}
