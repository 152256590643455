.widget {
  background-color: $dark-gray;
  box-shadow: inset 1px 1px 4px #000;
  border: 8px solid $medium-gray;
  padding: 15px 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  // @include media($mobile-screen-down) {
  //   @include fill-parent();
  // }
  // @include media($small-desktop-up) {
  //    @include fill-parent();
  // }
  h3 {
    text-align: center;
    line-height: 1.15;
    margin-bottom: .5em;
  	color: $light-gray;
    background: -webkit-linear-gradient(#fff, #afafaf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  button {
    cursor: pointer;
    margin: .5em auto 0;
    display: block;
  }
}

.sidebar {
  @include span-columns(12);
  margin: 1.5em 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media($medium-screen-up) {
    @include span-columns(4);
    margin: 0;
  }
  @include media($small-desktop-up) {
    @include span-columns(3.5);
    margin: 0;
  }

}

.calculator {
  .annual-savings,
  .vehicle-month {
    span {
      @include span-columns(1.85 of 3);
    }
    input {
      @include span-columns(1.15 of 3);
      margin-right: 0;
    }
  }
  .annual-savings {
    color: $gold;
    font-style: italic;
    position: relative;
   i {
      position: absolute;
      top: -3px;
      left: 68%;
   }
   input {
      background: transparent;
      border-bottom: 1px solid $gold;
      border-left: none;
      border-right: none;
      border-top: none;
      color: #ffffff;
      font-size: 14px;
      outline: none;
      text-indent: 10px;
      position: absolute;
      right: 0;
    }
   }

  .tooltip {
    text-align: center;
    cursor: pointer;
    display: block;
    margin-top: 1.5em;
    position: relative;
    p {
      color: #c1c1c1;
      font-size: 10px;
      text-decoration: none;
      display: inline-block;
      margin: 0;
      top: -3px;
      position: relative;
      padding-left: 5px;
      &:hover {
          text-decoration: underline;
      }
    }
    span.info-icon {
      background: transparent url(./images/question-icon1.png) no-repeat 0 0;
      height: 12px;
      width: 12px;
      display: inline-block;
    }


    .tooltip-popup {
      background-color: #ffffff;
      border: 8px solid #404040;
      color: #101010;
      display: none;
      font-size: 12px;
      padding: 15px;
      position: absolute;
      text-decoration: none;
      top: 25px;
      left: 0;
      width: 100%;
      z-index: 1000;
      @include media($small-desktop-up) {
        top: -197px;
        left: -275px;
      }
      ul li {
        list-style-type: disc;
        margin: 15px 0px 0px 15px;
        text-align: left;
        text-decoration: none;
      }
      .tooltip-close {
        background: url(./images/close-btn.png) no-repeat 0 0;
        height: 20px;
        cursor: pointer;
        width: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
  .clear-form {
    color: #c1c1c1;
    cursor: pointer;
    font-size: 10px;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    width: 80px;
    &:hover {
      :hover {
        text-decoration: underline;
      }
    }
  }
  .vehicle-month,
  .cost-per-vehcile,
  .annual-savings {
    height: 30px;
    line-height: 25px;
  }
}

.photo-gallery-box {
  text-align: center;
  .btn-tertiary {
    margin: 0 auto;
  }
}

.statement-paragraph {
   @extend h5;
   border-bottom: 1px solid #d2d2d2;
   margin-bottom: 0;
   padding-bottom: .75em;
}

.contact-details {
  .mailing-address,
  .shipping-address,
  .call-us {
    display: block;
    margin: 20px 0;
    span {
      display: block;
      margin: 3px 0;
      a {
        color: $text-gray;
      }
    }
  }
}


.testimonial-box {
 .entry-title {
   color: #f3a946;
   font-style: italic;
   line-height: 16px;
   margin: 10px 10px 0px 10px;
   text-align: left;
 }
 .entry-content {
   color: #bebebe;
   font-size: 11px;
   line-height: 16px;
   text-align: left;
 }
 strong {
   font-style: normal;
 }
}

.submit-photo-box {
  text-align: center;
  .btn {
    margin: 0 auto;
  }
}


.contact-box  {
  text-align: center;
  p {
    margin-bottom: 1.25em;
  }
  .contact-button {
    display: none;
    @include media($small-desktop-up) {
      display: block;
      margin: 0 auto;
      width: 120px;
    }
  }
  .contact-button-mobile {
    display: block;
    margin: 0 auto;
    width: 120px;
    @include media($small-desktop-up) {
      display: none;
    }
  }
}
