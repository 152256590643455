ul.testimonials {
  li {
    list-style-type: none;
    margin: 0;
  }
}

.testimonial {

  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid $base-border-color;
  border-top: 1px solid $white;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .featured-image {
    display: block;
    float: none;
    width: 100%;
  }
  span.company,
  span.city {
    display: block;
    font-style: italic;
  }
  span.company {
    margin-bottom: .25em
  }
}
