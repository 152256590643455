// Breakpoints
$mobile-screen: 460px;
$medium-screen: 768px;
$small-desktop: 1024px;

$mobile-screen-down: new-breakpoint(max-width $mobile-screen 12);
$medium-screen-down: new-breakpoint(max-width $medium-screen 12);
$medium-screen-up: new-breakpoint(min-width ($medium-screen + 1) 12);
$small-desktop-up: new-breakpoint(min-width $small-desktop 12);


// Typography
$base-font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
$heading-font-family: 'BebasNeueRegular', Verdana, Geneva, Arial, Helvetica, sans-serif;
//
// Font Sizes
$base-font-size: em(12);
//

// Colors
$black: #000000;
$dark-gray: #282828;
$medium-gray: #404040;
$text-gray: #8a8a8a;
$background-gray: #e2e2e2;
$border-gray: #afafaf;
$light-gray: #d7d7d7;
$white: #ffffff;
$gold: #f3a946;
$red: #dc1d00;

// Font Colors
$base-font-color: $text-gray;

//borders
$base-border-color: $border-gray;
