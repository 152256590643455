html {
  box-sizing: border-box;
  background-color: $background-gray;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  @include clearfix();
  max-width: 1040px;
  margin: 0 auto;
  padding: 2em 1em;
}

#wpadminbar {
  position: fixed!important;
  .header {
    position: relative;
  }
}

li#theme-switcher {
  background:#fff;
  border:10px solid red;
  padding:20px;
  position:fixed;
  top:0;
  right:0
 } /* Use this if you have the Theme Switcher Reloaded plugin (http://themebot.com/webmaster-tools/1-wordpress-theme-switcher-reloaded) installed and want to make it nice and obvious */


.content-wrap {
  @include span-columns(12);
	background-color: #e2e2e2;
  .content {
    margin: 0 auto;
    .content-area {
      @include span-columns(12);
      @include media($medium-screen-up) {
         @include span-columns(8);
       }
      @include media($small-desktop-up) {
        @include span-columns(8.5);
      }
    }
  }
}



.content-area,
.content-area-wide {
  ul {
	margin: 0px 0px 20px 0px;
  }
  li {
    font-size: 12px;
    line-height: 20px;
    list-style-type: disc;
    margin: 0px 0px 0px 20px;
  }
  a {
  	color: #d41800;
  	text-decoration: none;
    &:hover {
    	color: #afafaf;
    	text-decoration: none;
    }
  }
}

.content-area {
  .product-description {
    ul {
      li {
        	line-height: 20px;
      }
    }
  }
}
