.home-middle {
	background: #343434; /* Old browsers */
	background: -moz-linear-gradient(top,  #343434 0%, #000000 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #343434 0%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #343434 0%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#343434', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
	border-top: 2px solid #000;
	border-bottom: 1px solid #fff;
	box-shadow: 1px 1px 8px rgba(#000, .5);
	top: -1px;
	position: relative;
}


.slider-area {
	@include clearfix();
	background-color: #282828;
	border: 6px solid #404040;
	padding: 1em 1em .5em;
	margin-top: 0px;
	@include media($medium-screen-up) {
		margin-top: 35px;
	}
	@include media($small-desktop-up) {
		margin-top: 50px;
	}
	.slider-left {
		@include span-columns(12);
		@include media($medium-screen-up) {
			@include span-columns(6);
		}
		span.post-meta-key {
			display: none;
		}
		p.home-top-paragraph {
			margin: .5em 0 1.5em;
			color: #bcbcbc;
			font-size: 11px;
			text-align: center;
			@include media($medium-screen-up) {
				text-align: left;
			}
		}
		.btn {
			margin: 0 auto .75em;
			text-align: center;
			display: block;
			max-width: 200px;
			@include media($medium-screen-up) {
				margin: 0 0 0;
			}
		}
	}
	.slider-right {
		@include span-columns(12);
		@include media($medium-screen-up) {
			@include span-columns(6);
		}
		img {
			margin: 0 0 0 5px;
		}
		iframe {
			margin: 0;
			width: 100%;
		}
	}
}

.slider-headline {
	font-family: $heading-font-family;
	color: $light-gray;
	letter-spacing: 2px;
	line-height: 1;
	margin: 0 auto;
	text-align: center;
	@include media($medium-screen-up) {
		margin: 0;
		text-align: left;
	}

	.text-sm {
		font-size: em(33,12);
		display: block;
		background: -webkit-linear-gradient(#fff, #afafaf);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@include media($small-desktop-up) {
			font-size: em(48,12);
		}
	}
	.text-md {
		font-size: em(46,12);
		display: block;
		line-height: .9;
		background: -webkit-linear-gradient(#fff, #afafaf);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@include media($small-desktop-up) {
			font-size: em(65,12);
		}
	}
	.text-lg {
		font-size: em(50,12);
		display: block;
		line-height: .9;
		background: -webkit-linear-gradient(#fff, #afafaf);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@include media($small-desktop-up) {
			font-size: em(70,12);
		}
	}
}

.video-options {
	margin: 10px auto 5px;
	display: block;
	text-align: center;
	.select-video-title {
		display: inline-block;
		position: relative;
		top: -4px;
	}
	.options {
		display: inline-block;
		position: relative;
		.option1,
		.option2 {
			display: inline-block;
			padding-left: 10px;
			span {
				color: $light-gray;
				padding-left: 20px;
				font-family: $heading-font-family;
				background: -webkit-linear-gradient(#fff, #a1a1a1);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-size: em(21,12);
				letter-spacing: 1px;
			}
		}
		.option1 {
			span {
				padding-left: 23px;
			}
		}
	}
}

input[type=radio]:checked {
  -webkit-appearance: none;
	background-color: red;
	border: 3px solid $medium-gray;
	border-radius: 8px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	top: 7px;
	position: absolute;
	&:focus {
		outline:none;
	}
}

input[type=radio] {
	position: absolute;
	top: 8px;
	&:focus {
		outline:none;
	}
}

.main-content {
  border-bottom: 1px solid $base-border-color;
  padding-bottom: 1.5em;
}

.photo-gallery {
  border-top: 1px solid #fff;
  padding-top: 2em;
  position: relative;
  p.see-all-photos {
  	position: absolute;
  	top: 2em;
  	right: 0;
    a {
      color: #808080;
      font-size: 11px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
