/////////////////////////////////////////////
////    GRAVITY FORMS RESET STYLES     //////
/////////////////////////////////////////////

// These styles are made in mind to be used with SCSS
// If you use these styles, you must go to the Gravity Forms settings in the WordPress back end and set Output CSS to No.
// Ref for default reset: https://gist.github.com/forgeandsmith/8048759

//----- Core Containers
.gform_heading,
.gform_body,
.gform_footer {
	clear: both;
	padding-bottom: 10px;
	ul li {
		list-style-type: none;
		margin: 0;
	}
}
.gform_wrapper {
	margin-top: 1em;
}

////----- Fields Container
ul.gform_fields {
	list-style: none;
	margin: 0;
	padding: 0;
}

////----- Field Containers and their Contents
li.gfield {
	clear: both;
	overflow: auto;
	padding: 0 0 10px;
}
.ginput_container {
	input,
	textarea {
		border: 1px solid #ddd;
		&.large {
			width: 100%;
		}
		&.medium {
			width: 100%;
			padding: 8px 10px;
			@include media($medium-screen-up) {
				width: 50%;
			}
		}
		&.small {
			width: 100%;
			@include media($medium-screen-up) {
				width: 33%;
			}
		}
	}
	textarea.medium {
		width: 100%;
	}
}

.ginput_container_select {
  select {
		border: 1px solid #ddd;
		border-radius: 0px;
		background: #fff;
	  padding: 6px 10px 10px;
		width: 100%;
		height: 33px;
		background: #fff url(./images/dropdown-arrow.png) no-repeat 96% 52%; // Our Custom Caret
		@include appearance(none); // hide default browser carets
		@include media($medium-screen-up) {
			width: 50%;
		}
  }
}

label.gfield_label {
	display: inline-block;
	.left_label & {
		float: left;
		width: 25%;
	}
	.right_label & {
		float: right;
		width: 25%;
	}
}

.ginput_complex {
	label {
		font-size: em(14,18);
		margin: 0;
		padding-bottom: .5em;
		font-style: italic;
		font-weight: 400;
		color: $medium-gray;
	}
}

.gfield_description {
  margin-top: .25em;
  font-size: em(14,18);
	font-style: italic;
	color: $medium-gray;
}

.gfield_required {
	padding-left: 5px;
}

////----- Checkboxes and Radios
ul.gfield_checkbox,
ul.gfield_radio {
	padding: 0;
	li {
		margin-left: 0em;
		margin-bottom: 0;
	}
	input {
		margin-right: 10px;
		position: relative;
		top: -1px;
		display: inline-block;
	}
	label {
		display: inline-block;
		margin-bottom: 0;
	}
}
ul.gfield_radio {
	input {
		margin-left: 2px;
	}
}

////----- Section Break
.gsection {
	h2 {
		border-bottom: 3px solid black;
		padding-bottom: 15px;
	}
}

////----- Validation and Errors
.validation_error {
	color: $red;
	font-size: em(16,18);
	margin-bottom: 10px;
}

li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent!important;
  margin-bottom: 0px!important;
  padding-top: 0px!important;
  border: none!important;
}

li.gfield {
	&.gfield_error,
  &.gfield_creditcard_warning {
		background-color: transparent;
		margin-bottom: 0px;
		padding-top: 0px;

    .ginput_container {
      position: relative;
			 input,
			 select,
			 textarea {
				 	border: 1px solid $red;
					background: rgba($red, .15);
			 }
  		&.ginput_container_select {
    		&:after {
      		right: 20px;
    		}
  		}
    }

	}
	&.gfield_contains_required {
	}
	.validation_message,
	.instruction {
    //If you are planning to not display this message at all in favor of styles to suggest validation errors,
    // use an Accessible way of hiding this message for screen readers. Otherwise, style away
		color: $red;
		font-size: em(12,16);
		margin-top: 4px;
	}

	.instruction {
		margin-bottom: -5px;
	}
}

.gform_validation_container {
	display: none;
}

////----- CSS Ready Classes
.ginput_full {
	display: block;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}


input[type="submit"] {
	@extend .btn-tertiary;
}
