// Sidr Mobile Menu styles here

// Sidr specific vars
$sidebar-width: 260px;
$sidebar-width-neg: -260px;

.sidr-inner {
	.menu-primary-container,
  .header-right {
		display: block;
    position: relative;
	}
  .slogan {
    display: none;
  }
}

.mobile-menu {
  color: $red;
  cursor: pointer;
  display: block;
  font-size: em(18,18);
  position: absolute;
  right: 20px;
  top: 35px;

  @include media($medium-screen-up) {
    display: none;
  }
}

.sidr-open {
  .menu-bars {
      background-color: $light-gray;
      @include transform(rotate(45deg));
      &::before {
        opacity: 0;
        width: 0%;
      }
      &::after {
        top: 11px;
        left: 15px;
        background-color: $light-gray;
        @include transform(rotate(-90deg));
      }
  }
}
.menu-toggle {
  background-color: transparent;
  cursor: pointer;
  height: 30px;
  padding-top: 10px;
  position: relative;
  width: 30px;
  &:focus,
  &:hover {
    background-color: transparent;
    outline: none;
  }
}
.menu-bars {
  position: absolute;
  background-color: $light-gray;
  top:0;
  left: 0;
  width: 30px;
  height: 4px;
  @include transform(rotate(0deg));
  @include transform-origin(left center);
  @include transition(all .5s ease-in-out);
  &::before,
  &::after {
    content: "";
    display: block;
    position: relative;
    top: 9px;
    left: 0;
    color: $gold;
    background-color: $light-gray;
    width: 100%;
    height: 4px;
    @include transform(rotate(0deg));
    @include transform-origin(left center);
    @include transition(all .65s ease-in-out);
  }
  &::after {
    top: 14px;
  }

}

.sidr {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    margin: 0;
    z-index: 999999;
    width: $sidebar-width;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $black;

    .sidr-inner {
      padding: 0;
      display: block;
      clear: both;

      .menu-primary-container {
        ul {
          li {
            display: block;
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            &.current-menu-item {
              box-shadow: none;
            } //ancestors

            //Change all link colors / toggle icons based on background
            a, span {
              color: #fff;
            }

            a {
              text-align: left;
              font-size: em(21,12);
              padding: 10px 20px;
              display: block;
            }
          } //li
        } // ul
      }

      .secondary-menu {
        ul {
          li {
            a {
              color: #8e8d8d;
              font-size: em(14, 12);
              font-weight: bold;
              padding: 10px 20px;
              display: block;
            }
            &:first-child {
              a {
                padding-top: 20px;
              }
            }
            &:last-child {
              a {
                padding-bottom: 20px;
              }
            }
          }
        }
      }

      .buy-now-btn {
        margin: 0 auto;
        display: block;
        width: 85%;
      }

    } // sidr-inner

    &.right {
        left: auto;
        right: $sidebar-width-neg;
    }

    &.left {
        left: $sidebar-width-neg;
        right: auto;
    }
}
