#{$all-buttons},
button,
.btn {
  appearance: none;
  background-color: inherit;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-family: $heading-font-family;
  line-height: 0;
  padding: .85em 1em .75em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.btn-primary {
  @extend .btn;
  background-color: $red;
  color: $white;
  text-shadow: 1px 1px 3px #000;
  text-transform: uppercase;
  font-size: em(28,12);
  letter-spacing: 2px;
  -moz-box-shadow:    inset 0 0 8px #000000;
  -webkit-box-shadow: inset 0 0 8px #000000;
  box-shadow:         inset 0 0 8px #000000;
  &:hover {
    background-color: darken($red, 5%);
  }
}

.btn-secondary {
  @extend .btn;
  background: #cfcfcf; /* Old browsers */
  background: -moz-linear-gradient(top,  #ffffff 0%, #8b8b8b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ffffff 0%,#8b8b8b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ffffff 0%,#8b8b8b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#8b8b8b',GradientType=0 ); /* IE6-9 */
  color: #6e6e6f;
  text-shadow: 1px 1px 1px rgba(#fff, .5);
  text-transform: uppercase;
  font-size: em(28,12);
  letter-spacing: 1px;
  border: 1px solid #000;
  outline: 5px solid #000;
  &:hover {
    opacity: .75;
    color: $medium-gray;
    border: 1px solid #000;
    outline: 5px solid #000;
  }
}


.btn-tertiary {
  @extend .btn;
  background: #cfcfcf; /* Old browsers */
  background: -moz-linear-gradient(top,  #ffffff 0%, #8b8b8b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ffffff 0%,#8b8b8b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ffffff 0%,#8b8b8b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#8b8b8b',GradientType=0 ); /* IE6-9 */
  color: #6e6e6f;
  text-shadow: 1px 1px 1px rgba(#fff, .5);
  text-transform: uppercase;
  font-size: em(18,12);
  letter-spacing: 1px;

  &:hover {
    opacity: .75;
    color: $medium-gray;
  }
}

// div.radio span.checked {
//   background-image: url(./images/on-state-button.png);
// }
