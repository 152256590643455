.alignleft,
.left {
  display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright,
.right {
  display: inline;
	float: right;
	margin-left: 1.5em
}

.aligncenter,
.center {
//	@include center-block;
	clear: both;
}

.aligncenter {
  display:block;
  margin:0 auto
}

.wp-caption {
  border:1px solid #666;
  text-align:center;
  background:#ccc;
  padding:10px;
  margin:10px;
}

br.clear {
  clear:both;
  display:block;
  height:1px;
  margin:-1px 0 0 0
} /* Use this br class to clear your floated columns */
